
<template>
  <v-expansion-panel 
    @click="handleExpand"
  >
    <v-expansion-panel-header>Acttiv {{ $t('booking.acttiv.hotel', locale) }}</v-expansion-panel-header>
    <v-expansion-panel-content style="padding-top:16px;">
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-autocomplete
            v-model="acttivHotelID"
            :items="hotels"
            :loading="loading"
            outlined
            dense
            item-text="name"
            item-value="id"
            :label="$t('booking.acttiv.hotel', locale)"
            hide-details
            @change="handleChangeData"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-checkbox
            :label="$t('booking.hideReservationsRequired', locale)"
            v-model="acttivHideReservationsRequired"
            hide-details
            @change="handleChangeData"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-checkbox
            :label="$t('booking.allowReservation', locale)"
            v-model="acttivAllowReservation"
            hide-details
            @change="handleChangeData"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
        >
          <color-picker-item
            :value="acttivColor"
            :label="$t('common.color', locale)"
            :onChange="handleChangeColor"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
        >
          <v-text-field
            outlined
            :label="$t('booking.imagesFolder', locale)"
            v-model="acttivImagesFolder"
            hide-details
            @change="handleChangeData"
          />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import api from '@/services/api'
import axios from 'axios'
import ColorPickerItem from '@/components/common-components/ColorPickerItem'
export default {
  name: 'ActtivForm',
  components: {
    ColorPickerItem
  },
  props: {
    workspaceID: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    onChangeData: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    acttivHotelID: null,
    acttivHideReservationsRequired: false,
    acttivAllowReservation: false,
    acttivImagesFolder: null,
    acttivColor: 'red',
    hotels: [],
    loading: true
  }),
  methods: {
    getHotels () {
      axios.get('https://acttiv-api.dtouchwebapp.com/v1/public/hotels')
        .then(response => {
          this.hotels = [{
            id: null,
            name: '',
          },...response.data.map(item => {
            return {
              id: item.ID,
              name: item.Name.concat(` [${item.ID}]`)
            }
          })]
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          console.log(error)
        })
      /*
      api.getAllWithoutLimit('booking', 'v1/system/acttiv')
        .then(response => {
          this.hotels = [{
            id: null,
            name: '',
          }]
          this.hotels.push(...response.map(item => {
            return ({
              id: item.id_hotel,
              name: item.nombre
            })
          }))
          this.loading = false
      })*/
    },
    getActtivData () {
      api.getItem('booking', `v1/system/workspaces/`, `${this.workspaceID}/acttiv`)
        .then(response => {
          this.acttivHideReservationsRequired = response && response.ActtivHideReservationsRequired ? response.ActtivHideReservationsRequired : false
          this.acttivAllowReservation = response && response.ActtivAllowReservation ? response.ActtivAllowReservation : false
          this.acttivImagesFolder = response && response.ActtivImagesFolder ? response.ActtivImagesFolder : null
          this.acttivHotelID = response && response.ActtivHotelID ? response.ActtivHotelID : null
          this.acttivColor = response && response.ActtivColor ? response.ActtivColor: 'red'
        })
    },
    handleExpand () {
      this.getHotels()
      if(!this.acttivHotelID && this.workspaceID) this.getActtivData()
    },
    handleChangeData () {
      this.onChangeData({
        acttivHotelID: this.acttivHotelID,
        acttivHideReservationsRequired: this.acttivHideReservationsRequired,
        acttivAllowReservation: this.acttivAllowReservation,
        acttivImagesFolder: this.acttivImagesFolder,
        acttivColor: this.acttivColor,
      })
    },
    handleChangeColor (v) {
      this.acttivColor = v
      this.handleChangeData ()
    },
  },
}
</script>

